import React, { useState, useEffect, useContext,useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import { Grid, Box } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { config } from "../../util/apiconfig";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Button from '@mui/material/Button';
import Navbar from "../Navbar";
import Moment from 'react-moment';
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import BadgeIcon from '@mui/icons-material/Badge';
import { Button, Modal } from '@mui/material';
import axios from 'axios';
import companyImage from '../../assets/images/aes-logo.png';
import DataTable from './datatable';
import * as XLSX from 'xlsx';
import { format, differenceInMinutes } from 'date-fns';
// import handleExport from './datatable';


function VisitorReport() {
    const obj = useContext(NoteContext)
    const printRef = useRef();
    const navigate = useNavigate();

    const TodayDate = new Date();
    const [data, setData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [visitorData, setvisitorData] = useState([]);
    const [companyValue, setCompanyValue] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [visitorType, setVisitorType] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [visitingPerson, setVistingPerson] = useState('');


    const [visitingDate, setVisitingDate] = useState(dayjs(TodayDate));
    const [visitingEndDate, setVisitingEndDate] = useState(dayjs(TodayDate));
    const [readonlyCompany, setReadonlyCompany] = useState(false);

    async function getApprovedOnboardingList(company_id) {
        let data = await fetch(config.mongo_endpoint + "api/v1/visitor/viewallvisitor", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "x-access-token": `${localStorage.getItem('AgentToken')}`
            }
        });
        let res = await data.json();
        // console.log(res)
        if (res.status === "Success") {
            // console.log("manage_agent", res.data)
            if (company_id === 0) {
                // alert();
                setData(res.data);
            } else {
                const filterData = res.data.filter(rep => rep.company_id === company_id);
                setData(filterData)
            }



        } 
        // else {
        //     alert(res.message);

        // }

    }

    const getCompanyList = async () => {
        const type = window.localStorage.getItem("type");
        const company_code = window.localStorage.getItem("code");

        let companyData = await fetch(config.mongo_endpoint + `api/v1/company/getall?type=${type}?company_code=${company_code}`, {
            method: "GET",
            headers: {

                "x-access-token": window.localStorage.getItem("AgentToken"),

                // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGRhMjA1NWVlOWQyYzIxNTBhMWQyMzkiLCJpYXQiOjE2OTc2MTk2MjR9.AywLCafWb2JxXVUfDcwAaewGxQ9wFXj_-kUG2lyNz08"
            }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const companylist = resultCompanyData.data;
            //   companylist.map((res) => {
            //     setComcode(res.company_code)
            //     setComname(res.company_name)
            //   })
            setCompanyData(companylist);
            //   console.log(companylist)

        }
        console.log("CompanyData", resultCompanyData)
    }

    const getvisitorsList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/proof/getProof", {
          method: "GET",
        });
    
        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
          const companylist = resultCompanyData.data.proof;
          const visitortype = resultCompanyData.data.visitortype;
          setvisitorData(visitortype);
    
        }
        // console.log(resultCompanyData)
      } 

      const handleExport = () => {
        // Prepare data for export by mapping it into an array of objects
        const formattedData = data.map(row => {
          // Convert checkin and checkout to Date objects
          const checkInDate = new Date(row.checkin);
          const checkOutDate = new Date(row.checkout);
      
          // Calculate duration in minutes
          const durationMinutes = differenceInMinutes(checkOutDate, checkInDate);
          const formattedDuration = `${Math.floor(durationMinutes / 60)}h ${durationMinutes % 60}m`;
      
          return {
            'Company Name': row.company_name,
            'Visitor Name': row.visitor_name,
            'Visitor Company': row.visitor_company_name,
            'Phone No': row.phone_no,
            'Meeting Person': row.meeting_person,
            'Proof Type': row.proof_id,
            'Proof Document Number': row.document_no,
            'Purpose Of Visit': row.purpose_of_visit,
            'Check In': format(checkInDate, 'PPpp'),
            'Check Out': format(checkOutDate, 'PPpp'),
            'Duration': formattedDuration, // Duration in hours and minutes
          };
        });
      
        // Create a new workbook and add a worksheet
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Visitor Data');
      
        // Write the file to Excel and prompt download
        XLSX.writeFile(wb, 'visitor_data.xlsx');
      };
      
    

    useEffect(() => {
      const company_code = localStorage.getItem('code');
      const user_type = localStorage.getItem('type');

      if (user_type === "User") {

       
        setCompanyValue(company_code);
        setReadonlyCompany(true);
    }

    if (user_type === "User") {
      getApprovedOnboardingList(company_code);
    } else {
      getApprovedOnboardingList(0);
    }
        
        getCompanyList();
        getvisitorsList();
    }, [])



    const handleComany = (e) => {
        setCompanyValue(e.target.value);
        console.log("companyinfo", e.target.value);
        // getApprovedOnboardingList(e.target.value);

    }


    const handleVisitorTypeChange = (event) => {

        setVisitorType(event.target.value);

    };

    const handleVisitingDateChange = (event) => {
        const filterdDate = new Date(event);
        setVisitingDate(dayjs(filterdDate));
    };

    const handleVisitingEndDateChange = (event) => {
        const filterdDate = new Date(event);
        setVisitingEndDate(dayjs(filterdDate));
    };

    const handleSearch = () => {
        // setCompanyValue(companyName);
        //console.log("companyinfo", companyName);
        filterdata();

    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formatEndDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const filterdata = async () => {
        try {
            // Prepare the data manually as URL-encoded string
            const requestData = new URLSearchParams({
                "company_code": companyValue,
                "purpose_of_visit": visitorType,
                "start_date": formatDate(visitingDate),
                "end_date": formatEndDate(visitingEndDate)
            }).toString();
    
            // Configure the request with a different variable name
            const requestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${config.mongo_endpoint}api/v1/visitor/viewallvisitor`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: requestData
            };
    
            // Make the request using axios
            const resultEmployeeData = await axios.request(requestConfig);
    
            // Handle the response
            if (resultEmployeeData.status === 200) {
                const employeelist = resultEmployeeData.data.data;
                setData(employeelist); // Update the state with the fetched data
                console.log(employeelist); // Optional: Log the employee list
            }
        } catch (error) {
            console.error("Error fetching visitor data:", error);
            // Optionally handle the error (e.g., show an error message)
        }
    };

    const [img, setImg] = useState()
  const fetchPhoto = async(visitors) => {
    setImg('');
    const response = {'visitor_id': visitors.visitorId}
    await axios.post(config.mongo_endpoint+"api/v1/visitor/viewPhotoByOnBoardingID", response, { headers: {   'Content-Type': 'application/x-www-form-urlencoded',} })
          .then(result => {
            console.log("photo",result.data);
            if(result.status == 200){
              
              setImg(config.imagePath+result.data);
              
            } 
          })
          .catch(error => {
            // handle the error
            console.error(error);
            if (error) {
              console.log("Something went wrong!")
            //   toast.error("Something went wrong. Please try again Later....", {
            //   position: toast.POSITION.TOP_CENTER,
            //   theme: "colored",
            // });
    
            }
          
          });
  }
  const [visitorPass, setVisitorPasss] = useState({});
  const [open, setOpen] = useState(false);
    const handleOpen = async (resp) => {
        setOpen(true);
        setVisitorPasss(resp);
        // setCompanyData(localStorage.getItem('name'))
        await fetchPhoto(resp);
      };

      const handleClose = () => {
        setOpen(false);
      };

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Visitor Report </h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">
                                <Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                  
                                                    <InputLabel id="demo-simple-select-label">Company Name</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        size="small"
                                                        name="company_name"
                                                        label="Company Name *"
                                                        value={companyValue} // Set defaultValue to the desired default value
                                                        onChange={handleComany}
                                                        readOnly={readonlyCompany}
                                                    >
                                                        <MenuItem value="">Select Company</MenuItem>
                                                        {companyData.map((res) => (
                                                            <MenuItem key={res.company_code} value={res.company_code} >{res.company_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Visitor Type </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    size="small"
                                                    name="visitor_type"
                                                    label="Purpose of Visit *"

                                                    onChange={handleVisitorTypeChange}

                                                >
                                                     <MenuItem value="0">Select Purpose of Visit</MenuItem>
                                                    {visitorData.map((res) => (
                                                      <MenuItem value={res.proofId} key={res.proofId}>
                                                        {res.name}
                                                      </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* Left Section */}
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="Start Date "
                                                            name="visiting_date"
                                                            value={visitingDate}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            onChange={handleVisitingDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* Left Section */}
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="End Date "
                                                            value={visitingEndDate}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            onChange={handleVisitingEndDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="flex-end" >

                                            <Button
                                                type="submit"
                                                onClick={handleSearch}
                                                variant="contained"
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Search
                                            </Button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                                                                   
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={handleExport}
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Export To Excel
                                            </Button>

                                        </Grid> &nbsp;

                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </section>
                    <DataTable data={data} handleOpen={handleOpen}/>
                </div>
            </div>

            {open && (
        <Modal
            size={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="visitor-pass-modal-title"
            aria-describedby="visitor-pass-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
          
            <div style={{  
                alignItems: 'center',
                justifyContent: 'center', maxHeight: '800px', background: '#fff', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                    
                    <div ref={printRef}>
     <div style={{
  width: '377px',
  height: '566px',
  zIndex: 35,
  overflow: 'hidden',
  border: '2px solid #000000',
  background: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <div style={{
    width: '377px',
    height: '566px',
    paddingBottom: '57px',
    background: 'url() no-repeat center/100% 100%',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <div style={{
      width: '377px',
      height: '103px',
      paddingBottom: '1.5px',
      paddingTop: '4px',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column'
    }}>
      <div style={{
        width: '361px',
        height: '94px',
        marginLeft: '13px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <img src={companyImage} alt="Company Logo" style={{ width: '79px', height: '108px', display: 'flex' }} />
        <div style={{
          width: '254px',
          height: '74px',
          marginTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}>
          <span style={{
            width: '247px',
            height: '24px',
            color: '#000000',
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Inter',
            lineHeight: 1.4,
            whiteSpace: 'nowrap',
            display: 'flex'
          }}>
            {visitorPass.company_name}
          </span>
          <span style={{
            width: '247px',
            height: '43px',
            marginLeft: '7px',
            color: '#000000',
            fontSize: '11px',
            fontFamily: 'Inter',
            lineHeight: 1.4,
            display: 'flex'
          }}>
            {visitorPass.address}
          </span>
        </div>
      </div>
      <div style={{
        width: '376.01px',
        marginLeft: '-0.01px',
        border: '1px solid #000000',
        display: 'flex'
      }}></div>
    </div>
    <span style={{
      width: '94px',
      height: '19px',
      marginTop: '2px',
      marginLeft: '120px',
      color: '#000000',
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1.4,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      Visitor Information
    </span>
    <div style={{
      width: '376.01px',
      marginTop: '2.75px',
      marginLeft: '-0.01px',
      border: '1px solid #000000',
      display: 'flex'
    }}></div>
    <div style={{
      width: '123px',
      height: '135.95px',
      marginTop: '12.25px',
      marginLeft: '127px',
      background: '#FFFBFB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={img} alt="Visitor" style={{ width: '108.41px', height: '118.75px' }} />
    </div>
    <div style={{
      width: '377px',
      marginTop: '3.05px',
      border: '1px solid #000000',
      display: 'flex'
    }}></div>
    <div style={{
      width: '234px',
      height: '15px',
      marginTop: '9px',
      marginLeft: '14px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '76px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor Name
      </span>
      <span style={{
        width: '111px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.visitor_name}
      </span>
    </div>
    <div style={{
      width: '268px',
      height: '16px',
      marginTop: '14px',
      marginLeft: '14px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '99px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor Company
      </span>
      <span style={{
        width: '145px',
        height: '16px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.visitor_company_name}
      </span>
    </div>
    <div style={{
      width: '197px',
      height: '15px',
      marginTop: '14px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '67px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Contact No
      </span>
      <span style={{
        width: '73px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.phone_no}
      </span>
    </div>
    <div style={{
      width: '201px',
      height: '15px',
      marginTop: '10px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '107px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor ID Proof
      </span>
      <span style={{
        width: '77px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.document_no}
      </span>
    </div>
    <span style={{
      width: '63px',
      height: '11px',
      marginLeft: '25px',
      color: '#000000',
      fontSize: '10px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      {visitorPass.proof_id}
    </span>
    <div style={{
      width: '343px',
      height: '19px',
      marginTop: '11px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <span style={{
        width: '86px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Whom to Meet
      </span>
      <span style={{
        width: '222px',
        height: '19px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.meeting_person}
      </span>
    </div>
    <div style={{
      width: '343px',
      height: '25px',
      marginTop: '11px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <span style={{
        width: '86px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
       Purpose of Visit
      </span>
      <span style={{
        width: '222px',
        height: '25px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'normal',
        display: 'flex'
      }}>
        {visitorPass.purpose_of_visit}
      </span>
    </div>
    <span style={{
      width: '86px',
      height: '15px',
      marginTop: '9px',
      marginLeft: '14px',
      color: '#000000',
      fontSize: '13px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1.4,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      Date & Time
    </span>
    <div style={{
      width: '346px',
      height: '15px',
      marginTop: '8px',
      marginLeft: '18px',
      display: 'flex',
      alignItems: 'center'
    }}>
      <span style={{
        width: '18px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        In :
      </span>
      <span style={{
        width: '125px',
        height: '15px',
        marginLeft: '6px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        <Moment format="DD-MM-YYYY hh:mm A">{visitorPass.checkin}</Moment>
      </span>
      <span style={{
        width: '28px',
        height: '15px',
        marginLeft: '37px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Out :
      </span>
      <span style={{
        width: '125px',
        height: '15px',
        marginLeft: '7px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.checkout != null && (<Moment format="DD-MM-YYYY hh:mm A">{visitorPass.checkout}</Moment>) }
      </span>
    </div>
  </div>
</div>

    </div>
               
            </div>
        </Modal>
        )}





        </>
    );
}

export default VisitorReport;